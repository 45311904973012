import React, { FC } from "react";
import styles from "./SocialBanner.module.css";
import githubImage from "../../../assets/github-favicon.png";
import linkedinImage from "../../../assets/linkedin-favicon.svg";

interface SocialBannerProps {}

const SocialBanner: FC<SocialBannerProps> = () => (
	<div className={styles.SocialBanner}>
		<div id="SocialBannerLinkedin" className={styles.SocialBannerItem}>
			<a href="https://www.linkedin.com/in/shane-t98/">
				<img src={linkedinImage}></img>LinkedIn
			</a>
		</div>
		<div id="SocialBannerGithub" className={styles.SocialBannerItem}>
			<a href="https://github.com/shaneAPT">
				<img src={githubImage}></img>GitHub
			</a>
		</div>
	</div>
);

export default SocialBanner;
