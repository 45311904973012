import { FC, useEffect, useState } from "react";
import styles from "./RatingGame.module.css";
import { useCountdownTimer } from "use-countdown-timer";
import RatingGameMenuBar from "./RatingGameMenuBar/RatingGameMenuBar";
import RatingGameMovies from "./RatingGameMovies/RatingGameMovies";
import RatingGameMenu from "./RatingGameMenu/RatingGameMenu";
import RatingGameResults from "./RatingGameResults/RatingGameResults";
import RatingGameDevNotes from "./RatingGameDevNotes/RatingGameDevNotes";

interface RatingGameProps {}

export type Movie = {
  adult: boolean;
  backdrop_path: string;
  genre_ids: number[];
  id: number;
  original_language: string;
  original_title: string;
  overview: string;
  popularity: number;
  poster_path: string;
  release_date: string;
  title: string;
  video: boolean;
  vote_average: number;
  vote_count: number;
};

const RatingGame: FC<RatingGameProps> = () => {
  const questionsPerGame = 5;

  const [roundSettings, setRoundSettings] = useState<{
    yearGTE: string;
    yearLTE: string;
    country: string;
    genres: string;
  }>({ yearGTE: "2010-01-01", yearLTE: "2024-01-01", country: "", genres: "" });
  const [movies, setMovies] = useState<Movie[] | null>(null);
  const [loadingState, setLoadingState] = useState("selectionMenu");
  const [questionCount, setQuestionCount] = useState(1);
  const [showDevNotes, setShowDevNotes] = useState(false);
  const [score, setScore] = useState<{
    score: number;
    history: string[];
    lastResult: boolean;
  }>({
    score: 0,
    history: [],
    lastResult: false,
  });
  const { countdown, start, reset, pause, isRunning } = useCountdownTimer({
    timer: 1000 * 30,
    onExpire: () => {
      startNewRound();
      updateScores(false);
      setQuestionCount((count) => count + 1);
    },
  });

  const fetchData = () => {
    let apiUrl = `/api/themoviedb/ratingguess/newgame?`;

    if (roundSettings.yearGTE)
      apiUrl += `releaseYearGTE=${roundSettings.yearGTE}&`;
    if (roundSettings.yearLTE)
      apiUrl += `releaseYearLTE=${roundSettings.yearLTE}&`;
    if (roundSettings.country) apiUrl += `country=${roundSettings.country}&`;
    if (roundSettings.genres) apiUrl += `genres=${roundSettings.genres}&`;

    return fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((api1Data) => {
        setMovies(api1Data.movies);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const areMenuButtonsDisabled = (): boolean => {
    if (
      loadingState !== "activeGame" &&
      loadingState !== "results" &&
      loadingState !== "startingNewGame"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const resetRoundData = () => {
    reset();
  };

  const startNewRound = () => {
    resetRoundData();
    if (
      loadingState === "results" ||
      loadingState === "startingNewGame" ||
      questionCount !== questionsPerGame
    ) {
      fetchData().then(() => {
        setLoadingState("activeGame");
        start();
      });

      setLoadingState("loading");
    } else {
      setLoadingState("results");
    }
  };

  const resetGameData = () => {
    resetRoundData();
    setQuestionCount(1);
    setScore({
      score: 0,
      history: Array.from({ length: questionsPerGame }, () => "-"),
      lastResult: false,
    });
  };

  const startNewGame = () => {
    if (areMenuButtonsDisabled()) return;
    resetGameData();
    startNewRound();
  };

  const updateScores = (correctAnswer: boolean) => {
    let updatedHistory: string[] = score.history;
    updatedHistory.shift();
    updatedHistory.push(correctAnswer ? "Y" : "N");

    setScore((currentScore) => ({
      score: correctAnswer ? currentScore.score + 1 : currentScore.score,
      history: updatedHistory,
      lastResult: correctAnswer,
    }));
  };

  const submitAnswer = (param: number) => {
    pause();
    if (loadingState === "showAnswer") return; // Block the user double clicking an answer.

    fetch(`/api/themoviedb/ratingguess/checkanswer?answer=${param}`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.result === true) {
          updateScores(true);
        } else {
          updateScores(false);
        }
        questionCount < questionsPerGame &&
          setQuestionCount((count) => count + 1);
      })
      .finally(() => {
        setLoadingState("showAnswer");
        setTimeout(() => {
          startNewRound();
        }, 3000);
      });
  };

  const generateScoreImages = (): JSX.Element => {
    return (
      <>
        {score.history.map((value, index) => (
          <img
            key={index}
            src={(() => {
              switch (value) {
                case "Y":
                  return process.env.PUBLIC_URL + "/RatingGame/correct.svg";
                case "N":
                  return process.env.PUBLIC_URL + "/RatingGame/incorrect.svg";
                case "-":
                  return process.env.PUBLIC_URL + "/RatingGame/dash.svg";
                default:
                  return "";
              }
            })()}
          />
        ))}
      </>
    );
  };

  useEffect(() => {
    if (loadingState === "startingNewGame") startNewGame();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [roundSettings, loadingState]);

  let content;

  switch (loadingState) {
    case "loading":
    case "startingNewGame":
      content = (
        <div className={styles.RatingGameLoading}>
          <p>Loading...</p>
        </div>
      );
      break;
    case "activeGame":
    case "showAnswer":
      content = movies ? (
        <RatingGameMovies
          movies={movies}
          submitAnswer={submitAnswer}
          loadingState={loadingState}
          score={score}
        />
      ) : (
        <p>No data available</p>
      );
      break;
    case "selectionMenu":
      content = (
        <RatingGameMenu
          setRoundSettings={setRoundSettings}
          setLoadingState={setLoadingState}
          setShowDevNotes={setShowDevNotes}
        />
      );
      break;
    case "results":
      content = (
        <RatingGameResults
          score={score}
          generateScoreImages={generateScoreImages}
          setLoadingState={setLoadingState}
        />
      );
      break;
  }

  return (
    <div className={styles.RatingGame}>
      <RatingGameMenuBar
        loadingState={loadingState}
        setLoadingState={setLoadingState}
        score={score}
        questionCount={questionCount}
        countdown={countdown}
        generateScoreImages={generateScoreImages}
      />
      <div className={styles.RatingGameWrapper}>{content}</div>
      {showDevNotes ? (
        <RatingGameDevNotes
          setShowDevNotes={setShowDevNotes}
        ></RatingGameDevNotes>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RatingGame;
