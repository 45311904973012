import React, { FC, useEffect } from "react";
import styles from "./RatingGameDevNotes.module.css";

interface RatingGameDevNotesProps {
  setShowDevNotes: (value: boolean) => void;
}

const closeIcon = process.env.PUBLIC_URL + "/RatingGame/close.svg";
const exampleScreen = process.env.PUBLIC_URL + "/RatingGame/examplescreen.png";

const RatingGameDevNotes: FC<RatingGameDevNotesProps> = ({
  setShowDevNotes,
}) => {
  useEffect(() => {
    // Disable the page from scrolling when popup appears.
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  return (
    <div className={styles.RatingGameDevNotes}>
      <div className={styles.devNotesOverlay}>
        <div className={styles.devNotesPopup}>
          <div className={styles.DevNotesHeader}>
            <h1 className={styles.DevNotesHeaderTitle}>
              Rating Game Dev Notes
            </h1>
            <img src={closeIcon} onClick={() => setShowDevNotes(false)}></img>
          </div>
          <div className={styles.DevNotesOverview}>
            <div className={styles.DevNotesOverviewLeft}>
              <p>
                This small game was developed in order for me to practice
                building a React project from top to bottom, specifically
                hooking it up with a Node backend with an external API, and
                having varying states in the frontend. A bonus from this project
                was learning about Netlify.
              </p>
              <p>Technology used for developing this game:</p>
              <ul style={{ marginTop: 0 }}>
                <li>React (with TypeScript) frontend.</li>
                <li>TheMovieDB for movie data.</li>
                <li>Express/Node for the backend.</li>
                <li>Netlify for hosting.</li>
              </ul>
              <p>
                <b>
                  This development log is currently a work in progress. The game
                  logic is complete, however the CSS could use some extras as
                  well as some other cleanup tasks.
                </b>
              </p>
            </div>
            <div className={styles.DevNotesOverviewRight}>
              <img src={exampleScreen}></img>
            </div>
          </div>
          <h2>Backend Setup</h2>
          <h3>Overview</h3>
          <p>
            There's two API calls for the game. One is when a user starts a
            round, this API call returns two movies and all the given data for
            that movie as provided by TheMovieDB, except for the rating. The
            second is an API call that passes the user's given answer and
            returns whether or not that is the correct one.
          </p>
          <p>
            Technically the backend isn't needed and this game can be built
            without it, but it is an important proxy for calling TheMovieDB API.
            Without the backend, TheMovieDB API key would be exposed, and there
            would be no way of hiding the movie rating from the user (e.g. they
            could cheat through the Network tab).
          </p>
          <h3>User Session Management</h3>
          <p>
            As mentioned in the overview, I wanted to obscure the answer from
            users who could potentially cheat from reading what is returned in
            their browser's network tab. Therefore, the answer is not passed
            back in the API call to get the current round data. This means the
            backend has to keep note of the round data it just returned, what
            the answer is, and who that round belongs to. This is of importance
            when there's several users on the game at once from different
            devices.
          </p>
          <p>
            To achieve the active session management, express-session is used to
            generate session tokens. These are stored in an object on the Node
            backend, along with a timestamp and what the correct answer is for
            that session. When a user submits an answer, this session is updated
            with a new answer and timestamp. On the Node backend, there is a
            call every X minutes to a function which iterates through the
            sessions in the previously mentioned session object. The timestamps
            are checked and those sessions older than X minutes are deleted.
            Without the routine check to clear up inactive sessions, the object
            containing the current sessions will grow rapidly with sessions of
            unfinished games. It's worth noting, there's no clean-up of the
            session when a user fullys completes the game. The additional API
            call is not needed as the session will be deleted eventually anyway.
          </p>
          <h3>Movie Selection</h3>
          <p>
            The selection of what movies to show the user is relatively straight
            forward. A call is made to an API from TheMovieDB which returns a
            list of movies. To add an element of randomness to the game, this
            API is called and a page of 20 movies is selected from the page
            number 1 to N.
          </p>
          <p>
            On the page of 20 movies, a random number between 1 and 20 is
            selected. A second number is selected, and there is a catch to
            ensure it is not equal to the first number. These two numbers are
            used as the indexes for the movies on the page, and are the two
            movies that are returned.
          </p>
          <p>
            The API call that is used from TheMovieDB allows filtering of what
            movies are returned. These filters are used to add game modes to
            this game. Examples of the filters are release years, genres,
            country of origin. I implemented a few of these for demostration
            purposes in order to show there's a great deal of customisation
            possible.
          </p>
          <p>
            Some considerations had to be made, such as the previously mentioned
            check that the same movie is returned twice. Also, the number of
            pages in the initial API request is important. In my demo I use
            pages 1-5. A low range of pages ensures that there is always movies
            on that page. For example, if you choose a niche search criteria
            such as Irish comedies, there may only be 10 pages (200 movies).
          </p>
        </div>
      </div>
    </div>
  );
};

export default RatingGameDevNotes;
