import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/Home";
import TestPage from "./components/TestPage/TestPage";
import Movies from "./components/Movies/Movies";
import MoviesPopular from "./components/Movies/MoviesPopular/MoviesPopular";
import RatingGame from "./components/Movies/RatingGame/RatingGame";
import { isMobile } from "react-device-detect";
// import GuessWho from "./components/GuessWho/GuessWho";

function App() {
	// if (isMobile)
	// 	return (
	// 		<p>
	// 			This site currently does not support mobile. Please visit shaneapt.com
	// 			on your computer browser.
	// 		</p>
	// 	);
	return (
		<Router>
			<div className="App">
				<header className="App-header">
					<Link to={"/"} style={{ textDecoration: "none" }}>
						<h1>Shane Tully</h1>
					</Link>
				</header>
				<div className="App-body">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/testpage" element={<TestPage />} />
						{/* <Route path="/guesswho" element={<GuessWho />} /> */}
						<Route path="/movies" element={<Movies />}>
							<Route path={`/movies/popular`} element={<MoviesPopular />} />
						</Route>
						<Route path={`/movies/ratingguess`} element={<RatingGame />} />
					</Routes>
				</div>
			</div>
		</Router>
	);
}

export default App;
