import React, { FC } from "react";
import styles from "./BlogLinks.module.css";
import siliconRepubImage from "../../../assets/siliconrepublic.jpg";

interface BlogLinksProps {}

const BlogLinks: FC<BlogLinksProps> = () => (
  <div id={styles.BlogLinks}>
    <p id={styles.BlogLinksTitle}>Posts from me:</p>
    <ul>
      <li className={styles.BlogItem}>
        <a href="https://www.siliconrepublic.com/people/ai-engineer-fidelity-investments-software">
          <div className={styles.BlogItemWrapper}>
            <div className={styles.BlogItemImageWrapper}>
              <img src={siliconRepubImage}></img>
            </div>
            <div className={styles.BlogItemTextWrapper}>
              <div className={styles.BlogItemTitleWrapper}>
                <p className={styles.BlogItemTitle}>
                  Will AI be the next big addition to the engineer's toolbox?
                </p>
                <p className={styles.BlogItemSubtitle}>
                  Silicon Republic, 2023
                </p>
              </div>
              <p className={styles.BlogItemDescription}>
                Feature on me and my work with Fidelity Investments as part of
                Silicon Republic's Engineering Week
              </p>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
);

export default BlogLinks;
