import React, { FC } from 'react';
import styles from './Movies.module.css';
import MoviesPopular from './MoviesPopular/MoviesPopular';
import { Link, Route, Routes, Outlet } from 'react-router-dom';

interface MoviesProps {}

type MovieData = {
  adult: boolean;
  backdrop_path: string;
  genre_ids: number[];
  id: number;
  original_language: string;
  original_title: string;
  overview: string;
  popularity: number;
  poster_path: string;
  release_date: string;
  title: string;
  video: boolean;
  vote_average: number;
  vote_count: number;
};

const Movies: FC<MoviesProps> = () => (
  
  <div className={styles.Movies}>
    Movies Component
    {/* <MoviesPopular></MoviesPopular> */}
    <Link to={`/movies/popular`}>Popular</Link>
    <Link to={`/movies/ratingguess`}>Guess the Rating</Link>
    <Link to={`/movies/`}>Back</Link>
    <Outlet />
  </div>
);

export default Movies;
