import React, { FC, useState, useEffect } from "react";
import styles from "./MoviesPopular.module.css";

interface MoviesPopularProps {}

type Movie = {
  adult: boolean;
  backdrop_path: string;
  genre_ids: number[];
  id: number;
  original_language: string;
  original_title: string;
  overview: string;
  popularity: number;
  poster_path: string;
  release_date: string;
  title: string;
  video: boolean;
  vote_average: number;
  vote_count: number;
};

const MoviesPopular: FC<MoviesPopularProps> = () => {
  const [popularMovies, setPopularMovies] = useState<Movie[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [moviesSortSelection, setMoviesSortSelection] =
    useState<string>("popularity");

  const handleMoviesSortChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // Update the state with the selected value
    const selectValue = event.target.value;
    setMoviesSortSelection(selectValue);
    
    const updatedPopularMovies = popularMovies
      ? popularMovies.sort((a: Movie, b: Movie) => {
          switch (selectValue) {
            case "popularity":
              return b.popularity - a.popularity;
            case "vote_average":
              return b.vote_average - a.vote_average;
            default:
              return b.vote_average - a.vote_average;
          }
        })
      : null;
    setPopularMovies(updatedPopularMovies);
  };

  useEffect(() => {
    // Fetch data and update the state
    const fetchData = async () => {
      const apiUrl = "/api/themoviedb/movie/popular?language=en-US&page=1";

      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Process the data received from the API
          setPopularMovies(
            data.results.sort(
              (a: Movie, b: Movie) => b.popularity - a.popularity
            )
          );
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchData();
  }, []); // Dependency array ensures useEffect runs only once on mount

  return (
    <div className={styles.MoviesPopular}>
      <label htmlFor="moviesSort">Sort By: </label>
      <select
        id="moviesSort"
        name="moviesSort"
        value={moviesSortSelection}
        onChange={handleMoviesSortChange}
      >
        <option value="popularity">Popularity</option>
        <option value="vote_average">Rating</option>
      </select>
      <div className={styles.MovieCardWrapper}>
        {loading ? (
          <p>Loading...</p>
        ) : popularMovies ? (
          popularMovies.map((movie) => (
            <div className={styles.MovieCard} key={movie.id}>
              <img
                src={`https://image.tmdb.org/t/p/original${movie.poster_path}`}
              ></img>
              <p className={styles.MovieCardTitle}>{movie.title}</p>
              <p>
                {movie.popularity} {Math.floor(movie.vote_average)}/10 (
                {movie.vote_count})
              </p>
              <p></p>
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default MoviesPopular;
