import React, {
  Dispatch,
  FC,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import styles from "./RatingGameMenu.module.css";

interface RatingGameMenuProps {
  setRoundSettings: Dispatch<
    SetStateAction<{
      yearGTE: string;
      yearLTE: string;
      country: string;
      genres: string;
    }>
  >;
  setLoadingState: (loadingState: string) => void;
  setShowDevNotes: (value: boolean) => void;
}

const RatingGameMenu: FC<RatingGameMenuProps> = ({
  setRoundSettings,
  setLoadingState,
  setShowDevNotes,
}) => {
  const clickNewGame = (gameType: string = "default") => {
    switch (gameType) {
      case "default":
        setRoundSettings({
          yearGTE: "1970-01-01",
          yearLTE: "2024-01-01",
          country: "",
          genres: "",
        });
        break;
      case "2023":
        setRoundSettings({
          yearGTE: "2023-01-01",
          yearLTE: "2024-01-01",
          country: "",
          genres: "",
        });
        break;
      case "90s":
        setRoundSettings({
          yearGTE: "1990-01-01",
          yearLTE: "2000-01-01",
          country: "",
          genres: "",
        });
        break;
      case "irish":
        setRoundSettings({
          yearGTE: "",
          yearLTE: "",
          country: "IE",
          genres: "",
        });
        break;
      case "comedies":
        setRoundSettings({
          yearGTE: "1970-01-01",
          yearLTE: "2024-01-01",
          country: "",
          genres: "35",
        });
        break;
    }
    setLoadingState("startingNewGame");
  };

  return (
    <div className={styles.RatingGameMenu}>
      <div className={styles.MenuLeft}>
        <div className={styles.MenuHeaders}>
          <h1>Guess the rating</h1>
          <p>Two options, pick the movie with the higher rating.</p>
          <button onClick={() => setShowDevNotes(true)}>
            View Developer Notes
          </button>
          <p>
            Note: the movie ratings are not my own, I've no control of them.
          </p>
        </div>
      </div>
      <div className={styles.MenuRight}>
        <h1>Pick a category:</h1>
        <div className={styles.GameOptionButtons}>
          <button onClick={() => clickNewGame()}>Regular</button>
          <button onClick={() => clickNewGame("2023")}>2023</button>
          <button onClick={() => clickNewGame("90s")}>90's Only</button>
          <button onClick={() => clickNewGame("irish")}>Irish Movies</button>
          <button onClick={() => clickNewGame("comedies")}>Comedies</button>
        </div>
      </div>
    </div>
  );
};

export default RatingGameMenu;
