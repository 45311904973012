import React, { FC } from "react";
import styles from "./RatingGameMovies.module.css";
import { Movie } from "../RatingGame";

interface RatingGameMoviesProps {
  movies: Movie[];
  submitAnswer: (movieId: number) => void;
  loadingState: string;
  score: { score: number; history: string[]; lastResult: boolean };
}

const RatingGameMovies: FC<RatingGameMoviesProps> = ({
  movies,
  submitAnswer,
  loadingState,
  score,
}) => {
  return (
    <div className={styles.RatingGameMovies}>
      {movies.map((movie) => (
        <div
          className={styles.MovieCard}
          key={movie.id}
          style={
            {
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5) ), url(https://image.tmdb.org/t/p/original${movie?.backdrop_path})`,
              backgroundSize: "cover",
              backgroundBlendMode: "darken",
              backgroundPosition: "center",
              imageRendering: "pixelated",
            } as React.CSSProperties
          }
          onClick={() => submitAnswer(movie.id)}
        >
          <img
            src={`https://image.tmdb.org/t/p/original${movie.poster_path}`}
          ></img>
          <p className={styles.MovieCardTitle}>{movie.title}</p>
          <div className={styles.MovieScoreWrapper}>
            {loadingState === "showAnswer" ? (
              <p>{movie.vote_average}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      ))}
      {loadingState === "showAnswer" ? (
        <div className={styles.overlay}>
          <div className={styles.popup}>
            {score.lastResult ? (
              <h2
                style={
                  {
                    color: "green",
                  } as React.CSSProperties
                }
              >
                Correct
              </h2>
            ) : (
              <h2
                style={
                  {
                    color: "red",
                  } as React.CSSProperties
                }
              >
                Incorrect
              </h2>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RatingGameMovies;
