import React, { FC, useEffect, useState } from "react";
import styles from "./TestPage.module.css";
import io from "socket.io-client";

interface TestPageProps {}
// let socket = io("http://localhost:3001");

// type ActiveSockets = {
//   [key: string]: { active: boolean };
// };

// function buildActiveSocketsList(sockets: ActiveSockets | undefined) {
//   console.log("buildActiveSocketsList");
//   if (!sockets) return;
//   return (
//     <div>
//       {Object.keys(sockets).map((key) => (
//         <p key={key}>{key}</p>
//       ))}
//     </div>
//   );
// }

const TestPage: FC<TestPageProps> = () => {
  // const [currActiveSockets, setCurrActiveSockets] = useState<ActiveSockets>();

  // useEffect(() => {
  //   console.log("useEffectCalled");
  //   //on connection
  //   socket.on("connect", () => {
  //     //logic here
  //     console.log("connected to socket");
  //   });

  //   socket.on("message", (message: string) => {
  //     console.log("Received message from server:", message);
  //     let currEl = document.getElementById("chatBody");
  //     currEl!.textContent = message;
  //   });

  //   socket.on("userListUpdate", (users: ActiveSockets) => {
  //     let currEl = document.getElementById("activeUsers");
  //     console.log(users);
  //     setCurrActiveSockets(users);
  //   });
  // }, [currActiveSockets]);

  return (
    <div className={styles.TestPage}>
      <h1>Test Page</h1>
      {/* <button onClick={() => socket.emit("message", new Date())}>
        Send hello
      </button>
      <p id="chatBody"></p>
      <div id="activeUsers">{buildActiveSocketsList(currActiveSockets)}</div> */}
      {/* <video autoPlay={true} id="videoElement"></video> */}
    </div>
  );
};

export default TestPage;
