import React, { FC } from "react";
import styles from "./RatingGameResults.module.css";

interface RatingGameResultsProps {
  score: { score: number; history: string[] };
  generateScoreImages: () => JSX.Element;
  setLoadingState: (loadingState: string) => void;
}

type ScoreMessages = {
  0: { messages: string[] };
  1: { messages: string[] };
  2: { messages: string[] };
  3: { messages: string[] };
  4: { messages: string[] };
  5: { messages: string[] };
};

const scoreMessages: ScoreMessages = {
  0: {
    messages: [
      "You didn't get any correct. Better luck next time!",
      "Keep trying! Your movie knowledge will improve.",
      "No worries! Try again for a better score.",
    ],
  },
  1: {
    messages: [
      "Nice try! You got 1 correct.",
      "You're on the board with 1 correct answer!",
      "One correct! Can you do better next time?",
    ],
  },
  2: {
    messages: [
      "Not bad! You got 2 correct answers.",
      "Two correct! Can you aim for a higher score?",
      "Good effort! Keep it up.",
    ],
  },
  3: {
    messages: [
      "Well done! You got 3 correct answers.",
      "Three correct! You're doing great!",
      "Impressive! Can you keep it up?",
    ],
  },
  4: {
    messages: [
      "Fantastic! You got 4 correct answers.",
      "Four correct! You're a movie buff!",
      "Great job! Aim for a perfect score next time.",
    ],
  },
  5: {
    messages: [
      "Perfect score! You're a movie expert!",
      "Five correct! You nailed it!",
      "Incredible! You know your movies well.",
    ],
  },
};

const generateResultsMessage = (score: keyof ScoreMessages) => {
  return scoreMessages[score].messages[
    Math.floor(Math.random() * scoreMessages[score].messages.length)
  ];
};

const RatingGameResults: FC<RatingGameResultsProps> = ({
  score,
  generateScoreImages,
  setLoadingState,
}) => {
  return (
    <div className={styles.RatingGameResults}>
      <div className={styles.ResultsScoreImages}>{generateScoreImages()}</div>
      <p className={styles.ResultsMessage}>
        {generateResultsMessage(score.score as keyof ScoreMessages)}
      </p>
      <div className={styles.ResultsNav}>
        <div
          className={styles.ResultsNavHome}
          onClick={(event) => setLoadingState("selectionMenu")}
        >
          <img src={process.env.PUBLIC_URL + "/RatingGame/home.svg"} />
          <p>Main Menu</p>
        </div>
        <div
          className={styles.ResultsNavRefresh}
          onClick={(event) => setLoadingState("startingNewGame")}
        >
          <img src={process.env.PUBLIC_URL + "/RatingGame/refresh.svg"} />
          <p>Play Again</p>
        </div>
      </div>
    </div>
  );
};

export default RatingGameResults;
