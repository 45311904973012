import React, { FC } from "react";
import styles from "./Home.module.css";
import AboutMe from "./AboutMe/AboutMe";
import BlogLinks from "./BlogLinks/BlogLinks";
import SocialBanner from "./SocialBanner/SocialBanner";
import ExampleWork from "./ExampleWork/ExampleWork";

interface HomeProps {}

const Home: FC<HomeProps> = () => (
	<div className={styles.Home}>
		<SocialBanner></SocialBanner>
		<div className={styles.Body}>
			<AboutMe></AboutMe>
			<BlogLinks></BlogLinks>
			{/* <ExampleWork></ExampleWork> */}
		</div>
	</div>
);

export default Home;
