import React, { FC } from "react";
import "./AboutMe.css";

interface AboutMeProps {}

const AboutMe: FC<AboutMeProps> = () => (
	<div id="AboutMeWrapper">
		<p>Experienced Software Engineer based in Melbourne, Australia.</p>
		<p>
			To get in contact with me, please reach out to me on LinkedIn (linked
			above).
		</p>
		<p>Previously employed with Fidelity Investments and Aviva</p>
		<div id="skillsWrapper">
			<p>
				<b>-</b> Typescript/Javascript
			</p>
			<p>
				<b>-</b> React & Angular
			</p>
			<p>
				<b>-</b> Java
			</p>
			<p>
				<b>-</b> CI/CD, Docker & Jenkins
			</p>
		</div>
	</div>
);

export default AboutMe;
