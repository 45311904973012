import React, { FC, MouseEventHandler } from "react";
import styles from "./RatingGameMenuBar.module.css";

export interface RatingGameMenuBarProps {
  loadingState: string;
  setLoadingState: (loadingState: string) => void;
  score: { score: number; history: string[] };
  questionCount: number;
  countdown: number;
  generateScoreImages: () => JSX.Element;
}

const RatingGameMenuBar: FC<RatingGameMenuBarProps> = ({
  loadingState,
  score,
  questionCount,
  countdown,
  setLoadingState,
  generateScoreImages,
}) => {
  const checkIsButtonsEnabled = () => {
    // Menu buttons should be disabled when showing the user the answer.
    return loadingState !== "showAnswer";
  };

  return (
    <div className={styles.RatingGameMenuBar}>
      {loadingState === "activeGame" ||
      loadingState === "loading" ||
      loadingState === "showAnswer" ? (
        <div className={styles.RatingGameMenuBarWrapper}>
          <div className={styles.RatingGameMenuBarButtons}>
            <div
              className={styles.RatingGameMenuBarHome}
              onClick={(event) =>
                checkIsButtonsEnabled() && setLoadingState("selectionMenu")
              }
            >
              <img src={process.env.PUBLIC_URL + "/RatingGame/home.svg"} />
              <p>Main Menu</p>
            </div>
            <div
              className={styles.RatingGameMenuBarRefresh}
              onClick={(event) =>
                checkIsButtonsEnabled() && setLoadingState("startingNewGame")
              }
            >
              <img src={process.env.PUBLIC_URL + "/RatingGame/refresh.svg"} />
              <p>Refresh</p>
            </div>
          </div>
          <div className={styles.RatingGameScoreTracker}>
            {generateScoreImages()}
          </div>
          <div className={styles.RatingGameScoreTimer}>
            <img src={process.env.PUBLIC_URL + "/RatingGame/timer.svg"} />
            <p>{countdown / 1000}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RatingGameMenuBar;
